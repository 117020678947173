import React, { useEffect, useState } from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/template/Layout";
import { RegionPagePropsType, lang, RawAccommodation } from '../../entities/queriesTypes';
import { CurrentView } from '../../entities/templates';
import useSetGlobalState from '../../components/hooks/useSetGlobalState';
import { useGlobalStateContext,  ACTION_TYPES } from '../../Context/site';
import GalleryCarousel from "../../components/molecule/Glidejs/Gallery";
import Wrapper from '../../components/template/ContainerMax';
import { buildImageFocalPoint, formatMoney, getCountryPageTranslation as gCPT  } from '../../utils';
import Image from "../../components/atoms/Image";
import { Card } from '../../components/molecule/Card'
import translations from '../../entities/translations';
import PageSection from '../../components/organism/HomePageComponent';
import Link from "../../components/atoms/Link";
import PortableTextRenderer from "../../components/sanityBlockContents/PortableTextRenderer";

const IndexPage: React.FC<RegionPagePropsType> = (props) => 
{
  const { data, pageContext } = props
  const page = data && data.pageData
  page.parentView = 'region';
  const locale = pageContext && pageContext.locale
  const { state, stateDispatch } = useGlobalStateContext();
  useSetGlobalState({ page, pageContext, locale, currentView: CurrentView["specific-region"], parentView: 'subviews',
  useSubviewsAsMenu: true })
  const pageID = translations.globals.subMenuTranslation;
  const buttonTransaltion = translations.globals.buttons;
  const [showReadMore, setShowReadMore] = useState<boolean>(true)
  const _accomodationTranslation = translations.CountryPages.find((item) => item.menu === 'Unterkunfte')!;
  const [countryAccommodation, setCountryAccommodation] = useState<RawAccommodation [] | undefined>(undefined)
  const pageHash = translations.countryMenuID;
  const FROM = translations.globals.subMenuTripTranslation.Ab?.[state?.lang as lang];

  const regionUrl = () => {
    const url = `/${state?.lang}/country/${state?.allSlugs?.country && state?.allSlugs?.country[state?.lang as lang]?.current}/${gCPT({ view: CurrentView.regions, lang: state?.lang as lang})}`
    return url;
  }

  useEffect(() => {
    if (page?.accommodation?.length) {
      const _accommodation = [...page?.accommodation]
      if (_accommodation.length < 3) {
        setCountryAccommodation(_accommodation)
      } else {
        _accommodation.length = 3
        setCountryAccommodation(_accommodation)
      }
    }
  }, [page?.accommodation])

  const handleReadMore = () => {
    setCountryAccommodation(page?.accommodation)
    setShowReadMore(false)
  }

  useEffect(() => {
    if(stateDispatch && page?.title && state?.lang ) {
      stateDispatch(
        { 
          type: ACTION_TYPES.SET_SUBMENU_TITLE,
          key: 'submenuTitle',
          payload: page?.title[state.lang as lang]
        }
      )
    }
  }, [state?.subMenu?.title, stateDispatch, page?.title, state?.lang])

  return (
    <Layout seoTitle={'Specific Region'} meta={[]}>
      <Wrapper className="py-12" id={pageID.Orte[state?.lang as lang]!}>

        {page?.introText?.[state?.lang as lang]
          && <PortableTextRenderer body={page?.introText?.[state?.lang as lang]} />}

        {page?.regionGallery?.pictures?.length && 
          <div className="lg:w-10/12 my-10">
            <GalleryCarousel initClass='specificRegionGallery'>
              {page.regionGallery?.pictures?.map((item, i: number) => (
                <Image alt={(item?.alt && item?.alt[state?.lang as lang]) as string} 
                src={buildImageFocalPoint(item?.image?.asset) as string} key={i} className="glide__slide object-cover w-full lg:h-large" />
              ))}
            </GalleryCarousel>
          </div>}

        {page?.outroText?.[state?.lang as lang]
            && <PortableTextRenderer body={page?.outroText?.[state?.lang as lang]} />}

        <Link type="internal" to={regionUrl()} className="btn-base btn-secondary btn-small inline-block text-center mt-8">
          {translations.globals.navigation?.["Go back to"][state?.lang as lang]} &nbsp;
        </Link>
      </Wrapper>

      <PageSection
        readMoreLink={"#"}  
        sanityContext={null}
        showReadMore={!!page?.accommodation?.length && showReadMore}
        onClick={handleReadMore}
        id={pageID.Unterkunfte[state?.lang as lang]!}
        idTitle={pageID.Orte[state?.lang as lang]!}
        title={''} >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
          {countryAccommodation?.map((item, i: number) => <Card
            key={i}
            uniqueRef={item?.['_id']!}
            _className="mx-2 sm:w-full md:w-full lg:w-full mb-4"
            imageAlt={(item?.accommodationImage?.alt && item?.accommodationImage?.alt[state?.lang as lang]) as string}
            imageUrl={buildImageFocalPoint(item.accommodationImage?.image)}
            title={(item?.title && item?.title[state?.lang as lang]) || ''}
            ctaText={buttonTransaltion.WEITERLESEN[state?.lang as lang] || ''}
            cardType="ACCOMODATION"
            showEuroIcon={true}
            priceRating={item?.priceRating}
            ctaUrl={`/${state?.lang}/${item?.parentCountry?.slug?.[state?.lang as lang]?.current}/${
              _accomodationTranslation?.slug?.[state?.lang as lang]}/${item?.slug 
                && item?.slug?.[state?.lang as lang]?.current}#${pageHash?.Orte?.[state?.lang as lang]!}`}
            activities={(item?.cardTextShort && item.cardTextShort[state?.lang as lang]) || ''}
            location={(item.locationNameShort && item.locationNameShort[state?.lang as lang]) || ''}
            price={`${FROM} ${(formatMoney(item?.priceCategoryFrom || '', item.priceCategoryCurrency))}` || ''}
            cardSize={'SMALL'}
            /> )}
          </div>
      </PageSection>
    </Layout>
  )
}

export const query = graphql`
  query regionIndexPage($id: String!) {
    pageData: sanityRegion(_id: {eq: $id}) {
      id: _id
      title: _rawTitle
      subTitle: _rawSubTitle
      heroImage: _rawRegionImage(resolveReferences: {maxDepth: 4})
      introText: _rawIntroText
      outroText: _rawOutroText
      regionGallery: _rawRegionGallery(resolveReferences: {maxDepth: 4})
      accommodation: regionAccommodation {
        _id
        accommodationImage: _rawAccommodationImage(resolveReferences: {maxDepth: 10})
        title {
          de
         
        }
        locationNameShort {
          de
         
        }
        cardTextShort {
          de
         
        }
        parentCountry {
          slug {
            de {
              current
            }

          }
        }
        slug {
          de {
            current
          }

        }
        rating
        priceRating
        priceCategoryTo
        priceCategoryFrom
        priceCategoryCurrency
        priceCategoryExpanded {
          de
         
        }
      }
      parentCountry {
        title: _rawTitle
      }
    }
  }
`

export default IndexPage
