import React, { useEffect } from 'react';

import Glide, { Controls, Keyboard, Swipe, Autoplay } from '@glidejs/glide/dist/glide.modular.esm';
import ArrowNextIcon from '../../svg/Next_icon';
import ArrowPrevIcon from '../../svg/Prev_icon';
// import '@glidejs/glide/dist/css/glide.core.min.css';
import './style.css'

interface ICarousel {
  initClass?: string,
  carouselLength?: number
}

const Carousel: React.FC<ICarousel> = ({ children, initClass }) => {

  useEffect(() => {
    new Glide(`.glide-${initClass}`, {
      type: "carousel",
      perView: 1,
      autoplay: 5000,
      hoverpause: true,
    }).mount({ Controls, Keyboard, Swipe, Autoplay })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="w-full">
      <div className={`glide-${initClass} relative`}>
        <div data-glide-el="controls" className="hidden lg:flex justify-between absolute right-3 left-3 top-1/2 z-10">
          <button data-glide-dir="<" className="focus:outline-none"><ArrowPrevIcon /></button>
          <button data-glide-dir=">" className="focus:outline-none"><ArrowNextIcon /></button>
        </div>
        <div data-glide-el="track" className={`glide__track`}>
          <div className={`glide__slides`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Carousel